import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppWrapper from '../components/templates/AppWrapper';
import About from '../pages/About';
import BNPL from '../pages/BNPL';
import Compliance from '../pages/Compliance';
import Contact from '../pages/Contact';
import Faqs from '../pages/Faqs';
import Home from '../pages/Home';
import InvestmentProducts from '../pages/InvestmentProducts';
import Login from '../pages/Login';
import Media from '../pages/Media';
import Partners from '../pages/Partners';
import Privacy from '../pages/Privacy';
import SCF from '../pages/SCF';
import Terms from '../pages/Terms';
import VendorFinancing from '../pages/VendorFinancing';
import TreasuryFundraising from '../pages/TreasuryFundraising';
import DebtPlatforms from '../pages/DebtPlatforms';

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <AppWrapper>
                <Routes>
                    <Route
                        index
                        element={<Home />}
                    />
                    <Route
                        path='login'
                        element={<Login />}
                    />
                    <Route
                        path='partners/:type'
                        element={<Partners />}
                    />
                    <Route
                        path='partners/:type/:url'
                        element={<Partners />}
                    />
                    <Route
                        path='investment-products'
                        element={<InvestmentProducts />}
                    />
                    <Route
                        path='debt-platforms'
                        element={<DebtPlatforms />}
                    />
                    <Route
                        path='treasury-fundraising'
                        element={<TreasuryFundraising />}
                    />
                    <Route
                        path='frequently-asked-questions'
                        element={<Faqs />}
                    />
                    <Route
                        path='about'
                        element={<About />}
                    />
                    <Route
                        path='in-the-media'
                        element={<Media />}
                    />
                    <Route
                        path='contact'
                        element={<Contact />}
                    />
                    <Route
                        path='terms-and-conditions'
                        element={<Terms />}
                    />
                    <Route
                        path='privacy-policy'
                        element={<Privacy />}
                    />
                    <Route
                        path='regulatory-and-compliance'
                        element={<Compliance />}
                    />
                </Routes>
            </AppWrapper>
        </BrowserRouter>
    );
};

export default AppRoutes;
