import React from 'react';
import StaticBanner from '../components/organisms/StaticBanner';
import Container from '../components/atoms/Container';
import Cards from '../components/organisms/Cards';
import Heading from '../components/atoms/Heading';
import Paragraph from '../components/atoms/Paragraph';

const DebtPlatforms = () => {
    return (
        <StaticBanner size='xs'>
            <Container>
                <div className='hero-text'>
                    <Heading type='h1'>Associate Debt Platforms of Sponsors</Heading>
                    <Paragraph>
                        The associate debt platforms offer a comprehensive suite of debt products
                        tailored to meet diverse financing needs, enhancing financial flexibility
                        and facilitating strategic growth of corporates, startups, NBFCs, and MSMEs.
                    </Paragraph>
                </div>
                <br />
                <br />
                <div class='cards row'>
                    <div class='col-4 col-xs-12 mb-10 text-center'>
                        <div class='cards__item'>
                            <a
                                class=''
                                target='wsb'
                                href='https://wsbindia.com/'>
                                <div class='cards__item__img '>
                                    <img
                                        class=' rounded'
                                        src='/img/logos/wsb.jpg'
                                        alt='Image'
                                    />
                                </div>
                            </a>
                            <div class='cards__item__header undefined'>
                                <a
                                    class=''
                                    target='wsb'
                                    href='https://wsbindia.com/'>
                                    <h5 class='m-0'>WSB Real Estate Partners</h5>
                                </a>
                            </div>
                            <div class='cards__item__subheader undefined'></div>
                            <div class='cards__item__content'>
                                <p class='fs-sm m-0'>
                                    An alternate investment platform managing real estate
                                    investments in India.
                                </p>
                            </div>
                            <div class='cards__item__subheader'>Product - NCDs</div>
                            <div class='cards__item__footer'>Business vertical - Real Estate</div>
                        </div>
                    </div>
                    <div class='col-4 col-xs-12 mb-10 text-center'>
                        <div class='cards__item'>
                            <a
                                class=''
                                target='blacksoil'
                                href='https://blacksoil.co.in/'>
                                <div class='cards__item__img '>
                                    <img
                                        class=' rounded'
                                        src='/img/logos/blacksoil.jpg'
                                        alt='Image'
                                    />
                                </div>
                            </a>
                            <div class='cards__item__header undefined'>
                                <a
                                    target='blacksoil'
                                    class=''
                                    href='https://blacksoil.co.in/'>
                                    <h5 class='m-0'>BlackSoil Capital</h5>
                                </a>
                            </div>
                            <div class='cards__item__subheader undefined'></div>
                            <div class='cards__item__content'>
                                <p class='fs-sm m-0'>
                                    An alternative credit platform comprising an RBI-registered
                                    systemically important NBFC and a SEBI-registered AIF offering
                                    credit to Growth Companies, FIs, MSMEs NBFCs.
                                </p>
                            </div>
                            <div class='cards__item__subheader'>Product - NCDs</div>
                            <div class='cards__item__footer'>
                                Business vertical - NBFCs, Growth Companies & MSMEs
                            </div>
                        </div>
                    </div>
                    <div class='col-4 col-xs-12 mb-10 text-center'>
                        <div class='cards__item'>
                            <a
                                class=''
                                href='https://saralscf.com/'>
                                <div class='cards__item__img '>
                                    <a
                                        class=''
                                        target='saral'
                                        href='https://saralscf.com/'>
                                        <img
                                            class=' rounded'
                                            src='/img/logos/saralscf.jpg'
                                            alt='Image'
                                        />
                                    </a>
                                </div>
                            </a>
                            <div class='cards__item__header undefined'>
                                <a
                                    class=''
                                    target='saral'
                                    href='https://saralscf.com/'>
                                    <h5 class='m-0'>SaralSCF</h5>
                                </a>
                            </div>
                            <div class='cards__item__content'>
                                <p class='fs-sm m-0'>
                                    A B2B fintech platform that partners with mid-size enterprises
                                    to address the working capital requirements in their supply
                                    chain.
                                </p>
                            </div>
                            <div class='cards__item__subheader'>
                                Product - Co-Lending/Co-Investment Partnership
                            </div>
                            <div class='cards__item__footer'>
                                Business vertical - Corporates, MSMEs
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </StaticBanner>
    );
};

export default DebtPlatforms;
