import Header from "../organisms/Header"
import Footer from "../organisms/Footer"

const AppWrapper = ({ children }) => {
    return (
        <div className="wrapper">
            <Header />
            <main id="content" role="main" className="main">
                <div>
                    {children}
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default AppWrapper