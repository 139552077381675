import React from 'react'

const Container = ({ className, children, size, align, bg }) => {
    // padding
    // margin
    // borderRadius
    // container => sm, lg, xl 
    // align => center, left, right, justify

    let style = {};
    if (bg) {
        style.backgroundColor = bg;
    }

    let containerClass = 'container';
    if (size) {
        containerClass += ` container-${size}`;
    }
    if (align) {
        containerClass += ` text-${align}`;
    }

    return (
        <section style={style} className={className}>
            <div className={containerClass}>
                {children}
            </div>
        </section>
    )
}

export default Container