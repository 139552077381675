import React from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Paragraph from '../components/atoms/Paragraph';
import Stats from '../components/molecules/Stats';
import StaticBanner from '../components/organisms/StaticBanner';
import { DICT } from '../constants/dict';
import { STATS } from '../constants/stats';

const Home = () => {
    return (
        <>
            <StaticBanner size='none'>
                <div className='hero-text'>
                    <Heading
                        type='h1'
                        text='An Alternative Treasury Platform'
                    />
                    <Paragraph
                        text='AllnetATM is an alternative treasury platform uniquely designed to cater to family offices,
HNIs and UHNIs to help grow treasury yields through various debt products.'
                    />
                </div>
                <div className='hero-stats mb-50'>
                    <Stats
                        items={[
                            {
                                icon: 'community-line',
                                value: STATS.all.families,
                                desc: DICT.families,
                            },
                            {
                                icon: 'user-line',
                                value: STATS.all.raised,
                                desc: DICT.raised,
                            },
                            {
                                icon: 'article-line',
                                value: STATS.all.exited,
                                desc: DICT.exited,
                            },
                            {
                                icon: 'send-plane-2-line',
                                value: STATS.all.aum,
                                desc: DICT.aum,
                                currency: true,
                            },
                        ]}
                    />
                </div>
                {/* <PartnersScroll
                    url='all'
                    type='live'
                /> */}
            </StaticBanner>
            <div className='separator'></div>
            <Container className='text-center'>
                <Heading>The Rise of Family Treasury in India</Heading>
                <Paragraph>
                    Family wealth management in India is experiencing rapid growth, leading many
                    families to establish dedicated family offices to manage their wealth. Family
                    offices also maintain a portion of their wealth in liquid form, known as
                    Treasury, to address Asset Liability Mismatch (ALM) and other short-term needs.
                    Additionally, India is expected to witness a significant surge in High-Net-Worth
                    Individuals (HNI) and Ultra-High-Net-Worth-Individuals (UHNI) with excess liquid
                    capital to invest.
                </Paragraph>
                <Paragraph>
                    The Treasury can be invested in short-term schemes to generate profitable
                    returns. However, existing treasury products like bank fixed deposits (FDs) and
                    liquid mutual funds offer relatively low yields ranging from 4% to 7%.
                </Paragraph>
                <Paragraph>
                    AllnetATM aims to address this gap by initially increasing the treasury yields
                    of sponsor families, with plans to extend its services to other HNIs and UHNIs
                    families.
                </Paragraph>
            </Container>
        </>
    );
};

export default Home;

// TODO: Logos instead of names in About (Divide Education & Work Ex)
// TODO: investement products -> show companies who service these products
