import React, { useState } from 'react'
import Container from '../atoms/Container'
import Icon from '../atoms/Icon';

const FAQs = ({ items }) => {
    const [active, setActive] = useState(0);
    return (
        <div className="faqs">
            {
                items?.map((item, index) => {
                    return <div key={index} className="faqs__item">
                        <div className="faqs__item__question" onClick={() => setActive(index)}>
                            <h5>{item.q}</h5>
                            <Icon icon={index === active ? 'arrow-up-s-line' : 'arrow-down-s-line'} />
                        </div>
                        {
                            index === active &&
                            <p className="faqs__item__answer">{item.a}</p>
                        }
                    </div>
                })
            }
        </div>
    )
}

export default FAQs