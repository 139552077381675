import React from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Cards from '../components/organisms/Cards';
import StaticBanner from '../components/organisms/StaticBanner';
const Compliance = () => {
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Heading type='h1'>Regulatory & Compliance</Heading>
                    </div>
                </Container>
            </StaticBanner>
            <Container></Container>
        </>
    );
};
export default Compliance;
