import React, { useState } from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Paragraph from '../components/atoms/Paragraph';
import IconGroup from '../components/molecules/IconGroup';
import Cards from '../components/organisms/Cards';
import StaticBanner from '../components/organisms/StaticBanner';

const About = () => {
    const teams = [
        {
            title: 'AllnetATM Team',
            items: [
                {
                    img: '/img/team/shloka.jpg',
                    heading: 'Shloka Shetty',
                    subheading: 'Investments',
                    desc: 'With diverse sector expertise, Shloka heads Innovation at Allcargo Group and serves on the Board of Transindia Real Estate Ltd. She is also part of the Investment Committee at Allcargo Family Office. ',
                    footer: 'Education: North Western University<br />Work Exp: CDI Global, Deloitte, KPMG, Hive',
                },
                {
                    img: '/img/team/mohini.jpg',
                    heading: 'Mohini Gala',
                    subheading: 'Finance and Treasury',
                    desc: 'Mohini brings a decade of experience in investments, including managing an investment portfolio of the Navneet Family Office and working with BlackSoil Group.',
                    footer: 'Education: ICAI<br />Work Exp: BlackSoil Capital Pvt. Ltd.',
                },
                {
                    img: '/img/team/divya.jpg',
                    heading: 'Divya Gupta',
                    subheading: 'Business Development',
                    desc: 'Divya is a dynamic professional with a robust academic background and diverse expertise in strategy and business analytics.',
                    footer: 'Education: NYU, The University of Warwick',
                },
            ],
        },
        {
            title: 'Compliance',
            items: [
                {
                    img: '/img/team/theresa.jpg',
                    heading: 'Theresa Alexander',
                    subheading: 'Compliance Officer and Investor Relations',
                    desc: 'Theresa comes with 15 years of experience in compliance and investor relations. She is currently co-leading the IR team at BlackSoil. ',
                    footer: 'Work Exp: BlackSoil, Riya',
                },
            ],
        },
    ];
    const [selectedTeam, setSelectedTeam] = useState(teams[0]);
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Heading type='h1'>About Us</Heading>
                    </div>
                </Container>
            </StaticBanner>
            <Container>
                <Paragraph>
                    Allnet Financial Services is a 100% Jupiter Precious Gems & Jewellery
                    subsidiary, with Mr. Shashi Kiran Shetty and Aarti Shetty as Jupiter’s
                    promoters. Mr. Jatin Choksi, the Director of AllnetATM, previously served as
                    Chief Investment Officer and is presently the Managing Director of Transindia
                    Real Estate, a listed company focused on developing and leasing logistics
                    warehouses. AllnetATM’s other Director, Mr. Mohinder Bansal, formerly a
                    Management Advisor, currently serves as the whole-time director of Blacksoil
                    Capital, an NBFC specialising in providing alternative debt to SMEs and MSMEs.
                    AllnetATM is supported by four prominent family offices: Allcargo, Navneet,
                    Virender Gala, and Blacksoil.
                </Paragraph>
            </Container>
            {/* <Container
                size='lg'
                bg='#0d2b1a'
                className='container-dark color-white'>
                <Heading className='text-center mb-20'>Directors</Heading>
                <Cards
                    config={{
                        colClass: 'col-4 col-xs-12',
                        items: [
                            {
                                img: '/img/team/jatin.jpg',
                                heading: 'Jatin Chokshi',
                                subheading: 'MD, TransIndia Real Estate Ltd',
                                desc: 'Expertise: Investments & Treasury management, Taxation. A Chartered Accountant & Company Secretary by qualification and Former Vice President (Finance) of BASF.',
                                footer: 'Board Member (Past): AllCargo Logistics Limited, ECU Worldwide',
                            },
                            {
                                img: '/img/team/mpb.jpg',
                                heading: 'M P Bansal',
                                subheading: 'Founder & Director, BlackSoil',
                                desc: 'Expertise: Fund management, strategic advisory, capital markets, M&A, post-acquisition integration. An industry veteran with an illustrious career spanning over four decades and expertise in various domains across Asia, and Europe.',
                                footer: 'Former Board Member: Navneet Education Limited, AllCargo Logistics Limited',
                            },
                        ],
                    }}
                />
            </Container>
            <Container
                size='xl'
                bg='#0d2b1a'
                className='container-dark color-white'>
                <Heading className='text-center mb-20'>Promoters</Heading>
                <Cards
                    config={{
                        colClass: 'col-3 col-xs-12',
                        items: [
                            {
                                img: '/img/team/shashi.jpg',
                                heading: 'Shashi Kiran Shetty',
                                subheading: 'Chairman, Allcargo Group',
                                desc: "Allcargo, one of India's largest publicly listed logistics companies, operates in 160+ countries via 300+ offices. Market Cap of Allcargo is ₹8,800+ Cr",
                                footer: 'Allcargo backed by Investors: Tata Mutual Funds, Acacia Partners',
                            },
                            {
                                img: '/img/team/gnanesh.jpg',
                                heading: 'Gnanesh D Gala',
                                subheading: 'MD, Navneet Education',
                                desc: 'Navneet, a listed entity & dominant player in the field of publishing educational syllabi & stationery. Market Cap of Navneet is ₹3,450+ Cr',
                                footer: 'Navneet backed by Investors: HDFC Mutual Funds, Kotak Mutual Funds, Sundaram Mutual',
                            },
                            {
                                img: '/img/team/virendra.jpg',
                                heading: 'Virendra Gala',
                                subheading: 'Founder, Mahavir Agency',
                                desc: 'Mahavir Agency is a RE advisory firm. Mr.  Gala manages the investments & treasury vertical. Area Developed: 25 Mn sq.ft. Transaction Value: ₹10,000 Cr ',
                                footer: 'Developers Associated with: Sheth, Ahuja Contructions, Shree Nama Group',
                            },
                            {
                                img: '/img/team/mpb.jpg',
                                heading: 'M P Bansal',
                                subheading: 'Founder & Director, BlackSoil',
                                desc: 'Expertise: Fund management, strategic advisory, capital markets, M&A, post-acquisition integration. An industry veteran with an illustrious career spanning over four decades and expertise in various domains across Asia, and Europe.',
                                footer: 'Former Board Member: Navneet Education Limited, AllCargo Logistics Limited',
                            },
                        ],
                    }}
                />
            </Container> */}
            <div className='separator'></div>
            <Container className='text-center'>
                <div className='hero-text mb-50'>
                    <Heading>Investment Team</Heading>
                    <Paragraph>
                        Three young women, each representing their respective family offices, have
                        collaborated to establish this Alternative Treasury Platform to provide high
                        yields to family offices, HNIs and UHNIs for their treasury funds. The
                        sponsor families have achieved treasury earnings ranging from 10% to 15% per
                        annum by investing in various debt products offered by associated debt
                        platforms such as Blacksoil Capital (a non-real estate credit platform), WSB
                        Real Estate Partners (a real estate platform), and Saralscf (a Supply Chain
                        Fintech platform).
                    </Paragraph>
                </div>
                <div className='teams'>
                    <ul className='teams__nav'>
                        {teams.map((team) => {
                            return (
                                <li
                                    className={`teams__nav__item ${
                                        selectedTeam.title === team.title ? 'active' : ''
                                    }`}
                                    key={team.title}
                                    onClick={() => setSelectedTeam(team)}>
                                    {team.title}
                                </li>
                            );
                        })}
                    </ul>
                    <div className=''>
                        <Cards
                            config={{
                                class: 'row',
                                colClass: 'col-3 mb-30 col-xs-12',
                                items: selectedTeam.items,
                            }}
                        />
                    </div>
                </div>
            </Container>
            <div className='separator'></div>
            <Container className='text-center'>
                <Heading>Our Values</Heading>
                <IconGroup
                    config={{
                        className: 'mt-50',
                        itemClass: 'col-xs-12',
                        items: [
                            {
                                class: 'col-xs-12',
                                icon: '/img/values/integrity.png',
                                heading: 'Fiduciary Responsibility',
                                desc: 'We act as trustees for our stakeholders with a firm sense of accountability for the resources we use and the systems and processes we deploy.',
                            },
                            {
                                icon: '/img/values/people-first.png',
                                heading: 'People First',
                                desc: 'We prioritize to support such businesses that contributes for the people and the society.',
                            },
                            {
                                icon: '/img/values/entreprenure-centric.png',
                                heading: 'Entrepreneur Centric',
                                desc: 'Recognizing the value of entrepreneurship and aligning with their unique needs and aspirations.',
                            },
                        ],
                    }}
                />
                <IconGroup
                    className='mt-50'
                    config={{
                        className: 'mt-50',
                        itemClass: 'col-xs-12',
                        items: [
                            {
                                icon: '/img/values/transparency.png',
                                heading: 'Transparency',
                                desc: 'We maintain transparency and fairness in our operations, and follow robust governance practices.',
                            },
                            {
                                icon: '/img/values/sustainability.png',
                                heading: 'Environmental Sustainability',
                                desc: 'Committing to safeguard the environment through responsible investing and integrating sustainability through our efforts.',
                            },
                            {
                                icon: '/img/values/collaboration.png',
                                heading: 'Collaboration',
                                desc: 'We look for opportunities to collaborate with organizations that share our values, drive meaningful impact and our collective strength brings positive change.',
                            },
                        ],
                    }}
                />
            </Container>
        </>
    );
};

export default About;
