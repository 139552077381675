import React from 'react'

const Link = ({
    children,
    config,
    text = config?.text,
    href = config?.href,
    target = config?.target,
    className = config?.className || ''
}) => {
    return (
        <a className={className} href={href} target={target}>{text}{children}</a>
    )
}

export default Link