import React from 'react'
import Container from '../components/atoms/Container'
import Heading from '../components/atoms/Heading'
import Image from '../components/atoms/Image'
import Paragraph from '../components/atoms/Paragraph'
import FAQs from '../components/organisms/FAQs'
import StaticBanner from '../components/organisms/StaticBanner'

const Faqs = () => {
    const items = [
        {
            q: 'What is supply chain finance?',
            a: 'Supply chain finance, also known as supplier finance or reverse factoring, is a financial solution that helps businesses improve their cash flow along the supply chain. It allows suppliers to get early funds while buyers extend their payment terms.'
        },
        {
            q: 'How does supply chain finance work?',
            a: 'In supply chain finance, a financial institution acts as an intermediary between the buyer and the supplier. The supplier sells their invoices to the financial institution at a discounted rate and gets paid early. According to the invoice due date, the buyer repays the financial institution later.'
        },
        {
            q: 'What are the benefits of supply chain finance?',
            a: 'Supply chain finance offers several advantages, including: Better cash flow for both buyers and suppliers, Stronger relationships with suppliers by offering early payment options, Reduced supply chain risk and improved financial stability,	Improved working capital management, Potential for negotiated discounts and better pricing.'
        },
        {
            q: 'Who can benefit from supply chain finance?',
            a: 'Supply chain finance can benefit both buyers and suppliers. Buyers enjoy extended payment terms and improved working capital management, while suppliers benefit from early payment, which enhances their cash flow and reduces their dependence on costly financing options.'
        },
        {
            q: 'Is supply chain finance only for large companies?',
            a: 'No, supply chain finance benefits businesses of all sizes, from small enterprises to multinational corporations. Since supply chain finance can help boost cash flow and improve supplier relationships, it is used across the business spectrum.'
        },
        {
            q: 'How long does the supply chain finance process take?',
            a: 'The duration of the process depends on factors like transaction complexity and the efficiency of involved parties. However, with streamlined processes and digital platforms, the time between invoice approval and supplier payment can be significantly reduced.  '
        },
        {
            q: 'Is supply chain finance the same as factoring?',
            a: 'While supply chain finance and factoring both involve the financing of invoices, they have different focuses. Factoring deals with financing a company\'s accounts receivable, whereas supply chain finance focuses on improving the cash flow between buyers and suppliers along the supply chain.'
        },
        {
            q: 'How can I get started with supply chain finance?',
            a: 'If you are interested in exploring supply chain finance for your business, please get in touch with us through our contact page. Our team will be happy to discuss your specific needs and guide you through implementing supply chain finance solutions.'
        }
    ]
    return (
        <>
            <StaticBanner size="xs">
                <Container>
                    <div className="hero-text">
                        <Heading type="h1">Frequently Asked</Heading>
                        <Image className="shape" src='/img/shape-1.png' />
                    </div>
                    <div className="hero-text">
                        <Image className="shape" src='/img/shape-2.png' />
                        <Heading type="h1">Questions (FAQs)</Heading>
                    </div>
                    <Paragraph>
                        Welcome to the FAQ section. Here, you will find some commonly asked questions and their answers:
                    </Paragraph>
                </Container>
            </StaticBanner>
            <Container>
                <FAQs items={items} />
                <br /><br />
                <Paragraph>Please feel free to contact us directly if you have more questions or need further information.</Paragraph>
            </Container>
        </>
    )
}

export default Faqs