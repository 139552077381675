import React from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Image from '../components/atoms/Image';
import Paragraph from '../components/atoms/Paragraph';
import StaticBanner from '../components/organisms/StaticBanner';
import Section from '../components/organisms/Section';
import Cards from '../components/organisms/Cards';
import { Link } from 'react-router-dom';

const InvestmentProducts = () => {
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Heading type='h1'>Investment Products</Heading>
                    </div>
                    <Paragraph>
                        AllnetATM specialises in investing in various debt products originated by
                        the associate debt platforms of its Sponsors. The platform has developed
                        expertise in aligning the tenure of the Treasury with the visible cash exit
                        of linked investments.
                    </Paragraph>
                </Container>
            </StaticBanner>
            <Container>
                <div
                    className='text-center'
                    id='saral-solutions'>
                    <Heading align='center'>Products</Heading>
                </div>
                <br />
                <br />
                <div className='cards row '>
                    <div className='col-3 col-xs-12 mb-10 text-center'>
                        <div className='cards__item'>
                            <div className='cards__item__img cards__item__img-xs'>
                                <img
                                    className=' rounded'
                                    src='/img/products/property.png'
                                    alt='Image'
                                />
                            </div>
                            <div className='cards__item__header undefined'>
                                <h5 className='m-0'>
                                    Short-term loans against property &amp; other loans
                                </h5>
                            </div>
                            <div className='cards__item__footer justify-content-center'>
                                <Link to='/'>
                                    <img
                                        className='cards__item__footer__logo'
                                        src='/img/logo.png'
                                        alt='Image'
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-3 col-xs-12 mb-10 text-center'>
                        <div className='cards__item'>
                            <div className='cards__item__img cards__item__img-xs'>
                                <img
                                    className=' rounded'
                                    src='/img/products/companies.png'
                                    alt='Image'
                                />
                            </div>
                            <div className='cards__item__header undefined'>
                                <h5 className='m-0'>NCDs of SMEs, growth companies, and NBFCs</h5>
                            </div>
                            <div className='cards__item__footer justify-content-center'>
                                <Link
                                    target='blacksoil'
                                    to='https://www.blacksoil.co.in'>
                                    <img
                                        className='cards__item__footer__logo'
                                        src='/img/logos/bcpl.png'
                                        alt='Image'
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-3 col-xs-12 mb-10 text-center'>
                        <div className='cards__item'>
                            <div className='cards__item__img cards__item__img-xs'>
                                <img
                                    className=' rounded'
                                    src='/img/products/real-estate.png'
                                    alt='Image'
                                />
                            </div>
                            <div className='cards__item__header undefined'>
                                <h5 className='m-0'>NCDs of real estate developers</h5>
                            </div>
                            <div className='cards__item__footer justify-content-center'>
                                <Link
                                    target='blacksoil'
                                    to='https://www.wsbindia.com'>
                                    <img
                                        className='cards__item__footer__logo'
                                        src='/img/logos/wsb_flat.png'
                                        alt='Image'
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-3 col-xs-12 mb-10 text-center'>
                        <div className='cards__item'>
                            <div className='cards__item__img cards__item__img-xs'>
                                <img
                                    className=' rounded'
                                    src='/img/products/scf.png'
                                    alt='Image'
                                />
                            </div>
                            <div className='cards__item__header undefined'>
                                <h5 className='m-0'>
                                    Supply chain <br />
                                    financing
                                </h5>
                            </div>
                            <div className='cards__item__footer justify-content-center'>
                                <Link
                                    target='saralscf'
                                    to='https://www.saralscf.com'>
                                    <img
                                        className='cards__item__footer__logo'
                                        src='/img/logos/saral.svg'
                                        alt='Image'
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default InvestmentProducts;
