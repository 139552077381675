import React from 'react'
import Heading from '../atoms/Heading'
import Paragraph from '../atoms/Paragraph'

const Text = ({ config }) => {
    return (
        <>
            {
                config?.heading &&
                <Heading>{config.heading}</Heading>
            }
            {
                config?.paragraph &&
                <Paragraph>{config.paragraph}</Paragraph>
            }
            {
                config?.paragraphs.map((paragraph, index) => {
                    return <Paragraph key={index}>{paragraph}</Paragraph>
                })
            }
        </>
    )
}

export default Text