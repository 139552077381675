import React from 'react';
import Container from '../atoms/Container';
import Section from './Section';

const Footer = () => {
    const data = {
        logo: '/img/logo.png',
    };
    return (
        <footer className='footer'>
            <Container>
                <Section
                    config={{
                        items: [
                            {
                                col: 3,
                                colXs: 12,
                                type: 'section',
                                config: {
                                    items: [
                                        {
                                            type: 'logo',
                                            src: data.logo,
                                        },
                                        {
                                            type: 'paragraph',
                                            text: 'Allcargo House, C.S.T Road, Near Nityanand Restaurant, Opp: Amar Brass Co., KaLina, Santacruz East, Mumbai-98',
                                        },
                                        {
                                            type: 'link',
                                            config: {
                                                text: 'Email: info@allnetatm.com',
                                                href: 'mailto:info@allnetatm.com',
                                            },
                                        },
                                        {
                                            type: 'heading',
                                            config: {
                                                className: 'mt-20',
                                                type: 'h5',
                                                text: 'Associate Debt Platforms',
                                            },
                                        },
                                        {
                                            type: 'link',
                                            config: {
                                                className: 'my-10 color-bcpl',
                                                text: 'Blacksoil Capital Pvt. Ltd.',
                                                href: 'http://blacksoil.co.in/',
                                                target: '_blank',
                                            },
                                        },
                                        {
                                            type: 'link',
                                            config: {
                                                className: 'my-10 color-link-light',
                                                text: 'SaralSCF',
                                                href: 'https://saralscf.com/',
                                                target: '_blank',
                                            },
                                        },
                                        {
                                            type: 'link',
                                            config: {
                                                className: 'my-10 color-link-light',
                                                text: 'WSB',
                                                href: 'https://wsbindia.com/',
                                                target: '_blank',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                col: 2,
                            },
                            {
                                col: 4,
                                colXs: 12,
                                type: 'navigation',
                                config: {
                                    heading: 'Quick Links',
                                    items: [
                                        { text: 'Home', href: '/' },
                                        { text: 'About Us', href: '/about' },
                                        {
                                            text: 'Investment Products',
                                            href: '/investment-products',
                                        },
                                        { text: 'Contact Us', href: '' },
                                    ],
                                },
                            },
                            {
                                col: 3,
                                colXs: 12,
                                type: 'navigation',
                                config: {
                                    heading: 'Other Links',
                                    items: [
                                        { text: 'Privacy Policy', href: '' },
                                        {
                                            text: 'Terms & Conditions',
                                            href: '',
                                        },
                                        {
                                            text: 'Regulatory & Compliance',
                                            href: '',
                                        },
                                    ],
                                },
                            },
                        ],
                    }}
                />
            </Container>
        </footer>
    );
};

export default Footer;
