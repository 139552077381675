import React from 'react'

const Heading = ({
    children,
    config,
    type = config?.type || 'h2',
    text = config?.text || '',
    html = config?.html,
    align = config?.align,
    className = config?.className
}) => {
    if (align) {
        className += ` text-${align}`;
    }
    const Tag = `${type}`;
    return (
        <>
            {
                children &&
                <Tag className={className}>{children}</Tag>
            }
            {
                !children &&
                <>
                    {
                        html ? (
                            <Tag className={className} dangerouslySetInnerHTML={{ __html: html }} />
                        ) : (
                            <Tag className={className}>{text}</Tag>
                        )
                    }
                </>
            }
        </>
    )
}

export default Heading