import React, { useEffect, useState } from 'react'
import Logo from '../atoms/Logo'
import Navigation from '../molecules/Navigation'
const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const handleScroll = () => {
        if (window.scrollY > 50) { // Change 100 to your desired scroll threshold
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <header id="header" className={`header ${isScrolled ? 'scrolled' : ''}`}>
                <Logo />
                {/* {
                    isScrolled &&
                }
                {
                    !isScrolled &&
                    <Logo src='/img/logo.png' />
                } */}
                <Navigation />
            </header>
        </>
    )
}
export default Header