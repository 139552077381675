import React from 'react';
import StaticBanner from '../components/organisms/StaticBanner';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Paragraph from '../components/atoms/Paragraph';
import Cards from '../components/organisms/Cards';

const TreasuryFundraising = () => {
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Heading type='h1'>Treasury Fundraising</Heading>
                    </div>
                    <Paragraph>
                        AllnetATM raises funds from the Treasury of the Sponsor families, HNIs, and
                        UHNIs through instruments such as ICDs (Inter-Corporate Deposits), loans,
                        issuance of NCDs, borrowings, CPs (Commercial Papers), and other modes as
                        per applicable regulations. These treasury funds are raised for shorter
                        durations.
                    </Paragraph>
                    <br />
                    <br />
                    <div className='cards row '>
                        <div className='col-3 col-xs-12 mb-10 text-center'>
                            <div className='cards__item'>
                                <div className='cards__item__header bg-base'>
                                    <h5 className='m-0 color-white'>For warehousing investments</h5>
                                </div>
                                <div className='cards__item__subheader undefined'></div>
                                <div className='cards__item__content'>
                                    <p className='fs-sm m-0'>
                                        Bulk buy for an associate fund and/or an empanelled family
                                        office to bridge funding gaps.
                                    </p>
                                </div>
                                <div className='cards__item__footer justify-content-center'>
                                    <p className='fs-xs m-0 color-base'>15 day to 60 days</p>
                                    <small>Repayable after 15 Days</small>
                                </div>
                            </div>
                        </div>
                        <div className='col-3 col-xs-12 mb-10 text-center'>
                            <div className='cards__item'>
                                <div className='cards__item__header bg-base color-white'>
                                    <h5 className='m-0 color-white'>
                                        For down-sell <br /> investments
                                    </h5>
                                </div>
                                <div className='cards__item__subheader undefined'></div>
                                <div className='cards__item__content'>
                                    <p className='fs-sm m-0'>
                                        Bulk buy to match with the treasury cash inflow of an
                                        empanelled HNI and UHNI family.
                                    </p>
                                </div>
                                <div className='cards__item__footer justify-content-center'>
                                    <p className='fs-xs m-0 color-base'>90 day to 180 days</p>
                                    <small>Repayable after 90 Days</small>
                                </div>
                            </div>
                        </div>
                        <div className='col-3 col-xs-12 mb-10 text-center'>
                            <div className='cards__item'>
                                <div className='cards__item__header bg-base color-white'>
                                    <h5 className='m-0 color-white'>
                                        For AllnetTreaury investments
                                    </h5>
                                </div>
                                <div className='cards__item__subheader undefined'></div>
                                <div className='cards__item__content'>
                                    <p className='fs-sm m-0'>
                                        Proprietary treasury investments in debt products of
                                        associate debt platforms.
                                    </p>
                                </div>
                                <div className='cards__item__footer justify-content-center'>
                                    <p className='fs-xs m-0 color-base'>Minimum 1 Year</p>
                                    <small>Fixed Period</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Paragraph>
                        All products offered by the platform are closely monitored to ensure
                        end-to-end liquidity and manage associated risks effectively.
                    </Paragraph>
                </Container>
            </StaticBanner>
        </>
    );
};

export default TreasuryFundraising;
