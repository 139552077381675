import React from 'react'
import Text from '../molecules/Text'
import Image from '../atoms/Image';
import Heading from '../atoms/Heading';
import Paragraph from '../atoms/Paragraph';
import Paragraphs from '../molecules/Paragraphs';
import IconGroup from '../molecules/IconGroup';
import Stats from '../molecules/Stats';
import Logo from '../atoms/Logo';
import Link from '../atoms/Link';
import NavigationVertical from '../molecules/NavigationVertical';

const Section = ({ config }) => {
    let className = 'row';
    if (config.className) {
        className += ` ${config.className}`;
    }
    if (config.justify) {
        className += ` justify-content-${config.justify}`;
    }
    if (config.align) {
        className += ` align-items-${config.align}`;
    }
    return (
        <div className={className}>
            {
                config?.items.map((item, index) => {
                    let subClassName = (item.col ? `col-${item.col}` : 'col-12');
                    if (item.colXs) {
                        subClassName += ` col-xs-${item.colXs}`;
                    }
                    if (item.justify) {
                        subClassName += ` justify-content-${item.justify}`;
                    }
                    if (item.align) {
                        subClassName += ` align-items-${item.align}`;
                    }

                    subClassName += (item.order ? ` order-${item.order}` : ' order-1');

                    return <div key={index} className={subClassName}>
                        {
                            item.type === 'section' &&
                            <Section config={item.config} />
                        }
                        {
                            item.type === 'logo' &&
                            <Logo src={item.src} />
                        }
                        {
                            item.type === 'navigation' &&
                            <NavigationVertical config={item.config} />
                        }
                        {
                            item.type === 'heading' &&
                            <Heading html={item.html} text={item.text} config={item.config} />
                        }
                        {
                            item.type === 'paragraph' &&
                            <Paragraph html={item.html} text={item.text} config={item.config} />
                        }
                        {
                            item.type === 'link' &&
                            <Link text={item.text} config={item.config} />
                        }
                        {
                            item.type === 'paragraphs' &&
                            <Paragraphs config={item.config} />
                        }
                        {
                            item.type === 'ul' &&
                            <ul>
                                {
                                    item?.config?.items.map((li, liIndex) => {
                                        return <li key={liIndex}>{li}</li>
                                    })
                                }
                            </ul>
                        }
                        {
                            item.type === 'text' &&
                            <Text config={item.config} />
                        }
                        {
                            item.type === 'image' &&
                            <Image src={item.src} config={item.config} />
                        }
                        {
                            item.type === 'icon_group' &&
                            <IconGroup config={item.config} />
                        }
                        {
                            item.type === 'stats' &&
                            <Stats config={item.config} />
                        }
                    </div>
                })
            }
        </div>
    )
}

export default Section