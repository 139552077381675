import React from 'react'
import Container from '../components/atoms/Container'
import Heading from '../components/atoms/Heading'
import Image from '../components/atoms/Image'
import StaticBanner from '../components/organisms/StaticBanner'
import Cards from '../components/organisms/Cards'
const Media = () => {
    return (
        <>
            <StaticBanner size="xs">
                <Container>
                    <div className="hero-text">
                        <Image className="shape" src='/img/shape-2.png' />
                        <Heading type="h1">In the Media</Heading>
                        <Image className="shape" src='/img/shape-1.png' />
                    </div>
                </Container>
            </StaticBanner>
            <Container>
                <Cards config={{
                    colClass: 'col-4 col-xs-12',
                    items: [
                        {
                            img: 'https://bl-i.thgim.com/public/incoming/sfetn7/article65814510.ece/alternates/LANDSCAPE_1200/IMG_bl04_loan_1_1_OL9T064B.jpg',
                            heading: 'Gramophone launches credit financing to farmers',
                            desc: 'The start-up aims at clearing major obstacles in lending to the farming ecosystem with the network of micro entrepreneurs and a digital infrastructure',
                            descIcon: 'article-line',
                            link: {
                                text: 'Read This',
                                href: 'https://www.thehindubusinessline.com/economy/agri-business/gramophone-launches-credit-financing-to-farmers/article65814348.ece'
                            }
                        },
                        {
                            img: 'https://assets.entrepreneur.com/content/3x2/2000/1687530179-john-schnobrich-FlPc9-VocJ4-unsplash.jpg?format=pjeg&auto=webp&crop=16:9&width=675&height=380',
                            heading: 'Has BNPL Lost Its Sheen?',
                            desc: 'The MDRs are typically not high enough to cover the cost of capital and companies have to rely on high-risk customers for generating interest income," said Ankur Bansal, co-founder and director, Blacksoil Capital.',
                            descIcon: 'article-line',
                            link: {
                                text: 'Read This',
                                href: 'https://www.entrepreneur.com/en-in/news-and-trends/has-bnpl-lost-its-sheen/454694'
                            }
                        },
                    ]
                }} />
            </Container>
        </>
    )
}
export default Media