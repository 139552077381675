import React from 'react'
import Link from '../atoms/Link'

const NavigationVertical = ({
    config,
    items = config?.items,
    className = config?.className
}) => {
    return (
        <div className={`nav-vertical ${className}`}>
            <h4 className="nav-vertical__heading">{config.heading || 'Navigation'}</h4>
            <ul>
                {
                    items?.map((item) => {
                        return <li key={item.text}>
                            <Link 
                                className={item.className}
                                href={item.href} 
                                target={item.target}
                                config={item.config} >{item.text}</Link>
                        </li>
                    })
                }
            </ul>
        </div>
    )
}

export default NavigationVertical